.zones-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 20px 20px 0;
  min-height: calc(100vh - 105px);
  background: radial-gradient(50% 50% at 50% 50%, #0D579B 0%, #00315F 100%);
    .zone{
      margin: 0 1% 20px;
      padding-top: 20px;
      width: 14.6%;
      height: 180px;
      text-align: center;
      border: 1px solid #5B97DE;
      border-radius: 20px;
      background-color: #0B183F;
      .title{
        margin-top: 22px;
        line-height: 40px;
        color: #1565D8;
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      
      .wrap{
        word-wrap: break-word;
        margin-top: 0;
        margin-bottom: 0;
      }

      .msg {
        color: #5B97DE;
        font-size: 36px;
        font-weight: 600;
        span{
            margin-left: 20px;
        }
      }
      &:nth-child(3) {
        margin-right: 44%;
      }

      &:nth-child(6) {
        margin-right: 44%;
      }
      
    }

    .total{
        position: absolute;
        right: 2%;
        margin: 0 1% 20px;
        padding-top: 20px;
        width: 44%;
        height: 380px;
        text-align: center;
        border: 1px solid #5B97DE;
        border-radius: 20px;
        background-color: #fff;
        .title{
            margin-top: 80px;
            line-height: 40px;
            color: #1565D8;
            font-size: 100px;
            font-weight: bold;
            margin-bottom: 50px;
        }
        
        .wrap{
            word-wrap: break-word;
            margin-top: 0;
            margin-bottom: 0;
        }

        .msg {
            color: #5B97DE;
            font-size: 90px;
            font-weight: 600;
            svg{
                font-size: 70px;
            }
            span{
                margin-left: 20px;
            }
        }
        .msg{
            color: #3981d6;
        }
      }
}

@media screen and (min-width: 1900px) {
  .zones-box{
    padding: 40px 20px 0;
    .zone{
      margin: 0 1% 40px;
      height: 200px;
    }

    .total{
      height: 440px;
    }
  }
}