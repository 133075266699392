.ant-calendar-picker-icon{
  cursor: pointer;
}

.profiles .ant-table-wrapper{
  margin: 0 auto;
  width: 85%;

}

.profiles-form .ant-modal-footer{
  text-align: center;
}
.profiles .addWorker{
  cursor: pointer;
}
.profiles .addWorker span.text{
  float: right;
  margin-left: 10px;
  color: #1F68EA;
}