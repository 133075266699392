$primary-color:#1890ff;

.login {
    
    font-family: 'Poppins';
    height: 100vh;
    background: url('../../images/login_bg.png') left bottom no-repeat;
    // background-size: 800px auto;
    overflow: hidden;
    position: relative;

    .login-form {
        position: absolute;
        width: 540px;
        height: 747px;
        padding: 45px 45px 60px 45px;
        // top: 14%;
        bottom: 5%;
        right: 16%;
        border-radius: 40px;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.08);
        background-color: #fff;

        .login-title {

            h2 {
                margin-bottom: 50px;
                font-size: 20px;

                span {
                    color: #0089ED;
                    font-weight: 600;
                }
            }
            .page-name{
                margin-bottom: 20px;
                font-size: 55px;
                color: #000;
            }
        }

        .login-tip{
            margin-bottom: 15px;
            font-size: 14px;
            color: #000;
        }
        .mg-bottom{
            margin-bottom: 70px;
        }
        .mg-bottom2{
            margin-bottom: 50px;
        }
        .mg-bottom3{
            margin-bottom: 30px;
        }
    }

    .ant-input {
        padding: 0 20px;
        outline: none !important;
        height: 60px;
        line-height: 60px;
        background-color: rgba(255, 255, 255, 1) !important;
        color: #333 !important;
        font-size: 14px;

        border: 1px solid #ADADAD;
        border-radius: 9px;
        // -webkit-appearance: none;

        &:focus {
            border-color: #4285F4;
            outline: none;
            box-shadow: none;
        }

        ;
    }

    input:-webkit-autofill {
        border: 0;
        border-bottom: 1px solid #999;
        -webkit-text-fill-color: #333 !important;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
        background-image: none;
        outline: none !important;
        transition: background-color 50000s ease-in-out 0s;
    }

    .login-form-forgot {
        float: right;
        font-size: 13px;
    }

    .login-form-button {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-weight: 500;
        background: #0089ED;
        box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);
        border-radius: 10px;
    }
}

@media screen and (max-width: 1600px) {
    .login{
        background: url('../../images/login_bg.png') left bottom no-repeat;
        background-size: 800px auto;
        .login-form {
          bottom: 00px;
          right: 14%;
          transform: scale(0.9);
       }
    }
}

@media screen and (max-width: 1400px) {
    .login{
        background: url('../../images/login_bg.png') left bottom no-repeat;
        background-size: 700px auto;
        .login-form {
          bottom: -60px;
          right: 8%;
          transform: scale(0.8);
       }
    }
}