.register-box{
  padding-top: 100px;
  width: 100%;
  height: 100vh;
  background-color: #f3f3f3;
}

.register-content{
  margin: 0 auto;
  padding: 42px 24px 50px;
  border: 1px solid #ebedf0;
  width: 800px;
  background-color: #fff;
}

.register-content .ant-form{
  max-width: 600px;
}
.register-content .ant-btn-primary{
  margin-left: 140px;
}